.extra-wrapper {
    color: white;
    position: relative;
    padding-bottom: 2rem;
    background-color: black;
}

.extra-container {
    justify-content: space-around;
    align-items: flex-end;

}
.flexColStart .extra-left{
    margin: 20px;
    /* margin-left: 40px; */
}
.flexColStart .extra-desc{
    margin: 9px;
}
.extra-title{
    position: relative;
    z-index: 1;
}
.extra-title>h1{
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 4rem;
}

.extra-left{
    gap: 3rem;
}
.flexColStart.extraileft{
    margin-left: 100px;
    
}

.blueCircle{
    height: 4rem;
    width: 4rem;
    background-color: blue;
    border-radius: 999px;
    position: absolute;
    right: 27%;
    top: -10%;
    z-index: -1;
}

.image-container {
    width: 30rem;
    height: 40rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0;
    margin-right: 40px;
    /* border: 5px solid ; */
}

.image-container > img {
    width: 45rem;
    height: 45rem;
}

.search-bar{
    background-color: white;
    border-radius: 5px;
    border: 3px solid gray;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    width: 100%;
}

.search-bar>input{
    border: none;
    outline: none;
    color: black;
    width: 54%;
    /* height: 50%; */
    margin-left: 56px;
    margin-right: 40px;
    /* background-color: aquamarine; */
}

.stats{
    width: 100%;
    justify-content: space-between;
    /* color: black; */
}

.stat>:nth-child(1){
    font-size: 2rem;
    
}
.stat>:nth-child(1)>:last-child{
    color: orange;

}


@media (max-width: 640px) {
    .hero-container {
      margin-top: 2rem;
    }
    .extra-title > h1 {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    .image-container {
      width: 95%;
      height: 25rem;
    }
    .stats {
      justify-content: center;
      gap: 1.5rem;
    }
    .extra-right {
      width: 100%;
    }
    .stat > :nth-child(1) {
      font-size: 1.5rem;
    }
    .stat > :nth-child(2) {
      font-size: 0.8rem;
    }
  }